<template>
  <div>
    <div v-if="loading">
      <flixLoader text="... Check ..." />
    </div>
    <div v-if="error" class="flix-alert flix-alert-danger">
      {{ $t('message.bookingsNotAllowed') }}. {{ error[1] }} / {{ error[2] }} {{ $tc('message.appointments', error[2]) }}
    </div>
    <div v-if="success" class="flix-alert flix-alert-success">
      {{ $t('message.successfullSaved') }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    client: Object,
    group: Number,
    user: String,
    callback: Function
  },
  data () {
    return {
      diff: 0,
      loading: true,
      error: false,
      success: false
    }
  },
  mounted () {
    this.getCheck()
  },
  methods: {

    handleFreeAppoinments (ret) {
      if (!ret.data[0]) {
        this.callback(false)
        return false
      }

      var isBooked = ret.data[1]
      isBooked[1] = (isBooked[1] * 1) + this.diff

      if (isBooked[1] * 1 > isBooked[2] * 1) {
        this.error = isBooked
        this.loading = false
        setTimeout(function () { this.callback(false) }.bind(this), 3000)
        return false
      }
      this.loading = false
      this.success = true

      setTimeout(this.changeUser, 3000)
      return false
    },

    changeUser () {
      this.$flix_post({
        url: 'booking/change_group',
        data: {
          ID: this.client.md5ID,
          group: this.group,
          user: this.user
        },
        callback: this.setCallback
      })
    },
    setCallback (ret) {
      if (!ret.data[0]) {
        return false
      }
      this.client.group = this.group
      this.callback(this.group)
    },
    getCheck () {
      this.diff = this.group * 1 - this.client.group * 1

      if (this.diff === 0 || this.group * 1 === 0) {
        this.loading = false
        this.callback(false)
        return false
      }

      this.$flix_post({
        url: 'booking/check_date',
        data: {
          ID: this.client.form,
          begin: this.client.begin,
          end: this.client.end,
          group: 1,
          user: this.user
        },
        callback: this.handleFreeAppoinments
      })
    }
  }
}
</script>
